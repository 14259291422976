import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { BsFileEarmarkCheckFill, BsPersonCircle, BsFillCalendar2DateFill, BsPeopleFill, BsSearch, BsReverseListColumnsReverse } from "react-icons/bs";

const HelpHubNav = () => {

return (
    <>
        <Sidebar style={{ height: "100vh", width: "200px" }}  backgroundColor= "#6f03fc" >
            <h1>Help Hub</h1>
            <Menu renderExpandIcon={({ open }) => <span>{open ? '-' : '+'}</span>} >
                <SubMenu icon={<BsPersonCircle />} label="Profile" >
                    <MenuItem> Edit Profile </MenuItem>
                    <MenuItem> Personal Details </MenuItem>
                    <MenuItem> Values </MenuItem>
                    <MenuItem> Skills </MenuItem>
                </SubMenu>
                <SubMenu icon={<BsSearch />} label="Job Search">
                    <MenuItem> Job Search </MenuItem>
                    <MenuItem> Job Filters </MenuItem>
                    <MenuItem> Area </MenuItem>
                    <MenuItem> New Jobs </MenuItem>
                </SubMenu >
                <SubMenu icon={<BsFileEarmarkCheckFill />} label="Job Application" >
                    <MenuItem> Application </MenuItem>
                    <MenuItem> Contact Details </MenuItem>
                    <MenuItem> Tips </MenuItem>
                </SubMenu>
                <SubMenu icon={<BsReverseListColumnsReverse />} label="Job Post">
                    <MenuItem> Create Job </MenuItem>
                    <MenuItem> Share Job </MenuItem>
                    <MenuItem> Job Boost </MenuItem>
                    <MenuItem> Job Questions </MenuItem>
                </SubMenu>
                <SubMenu icon={<BsFillCalendar2DateFill />} label="Calendly">
                    <MenuItem> Calendly Link </MenuItem>
                    <MenuItem> Booking Interviews </MenuItem>
                    <MenuItem> Interview Conducting </MenuItem>
                    <MenuItem> Tips </MenuItem>
                </SubMenu>
                <SubMenu icon={<BsPeopleFill />} label="Team">
                    <MenuItem> Assign Team </MenuItem>
                    <MenuItem> Assign Roles </MenuItem>
                </SubMenu>
            </Menu>
        </Sidebar>
    </>
    );
  };
  
  export default HelpHubNav;