import onboarding from '../assets/resources/images/onboardingchecklist.png';
import recruitment from '../assets/resources/images/recruitmentstrategy.png';

const people = [
    {
      name: 'Onboarding Checklist',
      type: 'Check List',
      imageUrl: onboarding,
    },
    {
      name: 'Effective recruitment strategies',
      type: 'Guide',
      imageUrl: recruitment,
    },
    // More people...
  ]
  
  export default function ResourcesPage() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Resources and Guides</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the
              best information for our users.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          >
            {people.map((person) => (
              <li key={person.name}>
                <img className="w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.type}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  