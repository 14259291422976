import React from 'react';
import Header from '../../assets/images/header/helphub.png'
import Pattern2 from '../../assets/images/header/pattern-2.svg';
import Pattern3 from '../../assets/images/header/pattern-3.svg';
import Shadow1 from '../../assets/images/header/shadow-1.svg';
import Shadow2 from '../../assets/images/header/shadow-2.svg';
import '../../assets/styling/blog/blog.css'

const HelpHubHeader = () => {
    return (
        <>      
            <section class="hero" id="home" aria-label="home">
                <div class="container">
                    <div class="hero-content">
                    
                    <h1 class="headline headline-1 section-title">
                        Help <span class="span">Hub</span>
                    </h1>
                    <p class="hero-text">
                    Whether you're a newcomer or a seasoned user, our step-by-step instructions and handy tips will empower you to maximize your experience with Kwirl. 
                    From setting up your account to mastering advanced features, our user-friendly guides will ensure that you navigate Kwirl with confidence and efficiency.
                    </p>

                    </div>

                    <div class="hero-banner">
                        <img src={Header} width="327" height="490" alt="Kwirl Blog" class="w-100"></img>
                        <img src={Pattern2} width="27" height="26" alt="shape" class="shape shape-1"></img>
                        <img src={Pattern3} width="27" height="26" alt="shape" class="shape shape-2"></img>
                    </div>

                    <img src={Shadow1} width="500" height="800" alt="Shadow" class="hero-bg hero-bg-1"></img>
                    <img src={Shadow2} width="500" height="500" alt="Shadow" class="hero-bg hero-bg-2"></img>

                </div>
            </section>
        </>
    )
}

export default HelpHubHeader;