import {
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  UsersIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import world from '../assets/images/others/Website.png';
import inbox from '../assets/images/others/inbox.png';

const transferFeatures = [
  {
    id: 1,
    name: 'Collaboration',
    description:
      'Now more than ever collaboration is key. Stay ahead of the game with an innovative approach to collaborating with team members anytime, anywhere. We strive to create connections, facilitate meaningful interactions, and foster an environment where both sides can thrive',
    icon: UsersIcon,
  },
  {
    id: 2,
    name: 'Innovative Platform',
    description:
      'We believe in empowering job seekers by providing them with the tools, resources, and insights they need to succeed in their career endeavours',
    icon: BoltIcon,
  },
  {
    id: 3,
    name: 'Transparency',
    description:
      'By embracing openness, we empower you to make informed decisions, save time, and find the perfect career fit',
    icon: EyeIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'Notifications',
    description:
      'Be notified every step of the way, always keeping you in the loop',
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    id: 2,
    name: 'Emails',
    description:
      'Moved to the next round? Employer requires documents? Need to book an interview? Dont worry, thorugh our flows our app will send keep you updated every step of the way!',
    icon: EnvelopeIcon,
  },
]

export default function Example() {
  return (
    <div className="overflow-hidden py-16 lg:py-24 mb-12">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <p className="mt-2 font-bold tracking-tight text-white sm:text-xl">
          Endless Possibility
          </p>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Engage, Connect, Collaborate.          
          </p>
        </div>

        <div className="relative mt-8 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-violet-900 sm:text-3xl">What We Do</h3>
            <p className="mt-3 text-lg text-gray-200">
            We believe that finding the right job, no matter where you are situated, should be a fulfilling and exciting journey, not a daunting and tiresome process.
            <br/><br/>We have set out to develop an innovative platform that combines cutting-edge technology, intuitive design, and personalised features to provide genuine opportunity for a hiring experience like no other. 
            <br/><br/>We have reimagined the way job seekers connect with employers along with collaboration between employers, recruiters and hiring managers. We believe that finding the right job should be a fulfilling and exciting journey, not a daunting and tiresome process for all parties. 
            <br/><br/>What sets Kwirl apart is our unwavering commitment to delivering excellence. We strive to provide a platform that is user-centric, feature-rich, and constantly evolving to meet your changing needs. We listen to your feedback, harness the power of advanced algorithms, and leverage the latest industry trends to ensure that our software remains at the forefront of innovation. 


What are you waiting for? 
            </p>

            <dl className="mt-10 space-y-10 mb-16">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-violet-500 text-white">
                      <item.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-violet-900">{item.name}</p>
                  </dt>
                  <dd className="ml-16 mt-2 text-base text-gray-200">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto"
              width={490}
              src={world}
              alt=""
            />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-violet-900 sm:text-3xl">Always in the loop</h3>
              <p className="mt-3 text-lg text-gray-300">
              Keep tabs on your job applications with clarity and precision. Our workflows provide real-time updates and notifications, allowing you to track your progress and stay informed at every stage. Transparency you haven’t had before.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-violet-500 text-white">
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-violet-900">{item.name}</p>
                    </dt>
                    <dd className="ml-16 mt-2 text-base text-gray-300">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto"
                width={490}
                src={inbox}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
