/* Theme colours */

import React from 'react';
import { useSelector } from 'react-redux';

export default function ThemeProvider({children}) {
    const {theme} = useSelector(state => state.theme)
  return (
    <div className={theme}>
        <div className='bg-violet-600 text-gray-300 dark:bg-gray-900 min-h-screen'>
      {children}
      </div>
    </div>
  )
}