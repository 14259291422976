import React from 'react';
import Tilty from 'react-tilty';

// Images
import Stripe from '../../assets/images/others/stripe.png';
import Calendly from '../../assets/images/others/calendly.png';
import Document from '../../assets/images/others/document-upload.png';
import Offer from '../../assets/images/others/joboffer.png';

const PossibilityData = 
[

    {
        "id": 1,
        "thumb": Stripe,
        "subtitle": "Stripe",
        "title": "Experience seamless transactions with Kwirl's integrated Stripe payment solution. ",
        "paragraph": "Elevate your user experience by securely processing payments, subscriptions, and transactions directly on our platform. With Stripe, we bring you a hassle-free and reliable payment experience, ensuring a smooth journey for both job seekers and recruiters alike. Trust in Kwirl and Stripe to redefine convenience in every interaction. "
    },
    {
        "id": 2,
        "thumb": Calendly,
        "subtitle": "Calendly",
        "title": "Effortless scheduling, streamlined interactions.",
        "paragraph": "Kwirl is proud to integrate with Calendly, making appointment setting a breeze for recruiters and job seekers alike. Seamlessly align your calendars, effortlessly manage interviews, and optimize your time with our Calendly integration. Elevate your scheduling experience, because at Kwirl, we believe your time is too precious for complexities."
    },
    {
        "id": 3,
        "thumb": Document,
        "subtitle": "Document Upload",
        "title": "Simplify your application process with Kwirl's Document Upload feature.",
        "paragraph": "Seamlessly submit and manage your essential documents directly through our platform. Whether you're a job seeker sharing your resume or an employer collecting crucial paperwork, our intuitive document upload ensures a smooth and secure exchange. Elevate your recruitment journey with Kwirl – where efficiency meets simplicity."
    },
    {
        "id": 4,
        "thumb": Offer,
        "subtitle": "Job Offer",
        "title": "Seal the deal seamlessly with Kwirl's innovative Job Offer Letter feature.",
        "paragraph": "Automatically sending employment offers directly through our platform, making the hiring process swift and digital. With Kwirl, empower your recruitment journey by extending job offers effortlessly and ensuring a smooth transition for the newest member of your team. Elevate your hiring experience – because at Kwirl, success starts with simplicity. "
    }
];


const FeaturesPossibility = () => {
    return (
        <>
            <div className="section section-padding pb--70 possibility pt-10">
            <div className="section-title mb-45">
                    <span className="sub-title possibility">Our Features</span>
                    <h2 className="title">
                    Integrations, scheduling, <span>Easy Documents.</span>
                    </h2>
                </div>

                <div className="p-10">
                    {PossibilityData.map((data) => (
                        <div key={data.id} className={`process-work ${(data.id % 2  === 0) ? "content-reverse" : ""}`}>
                            <Tilty perspective={3000}>
                                <div className="thumbnail">
                                    <img src={data.thumb} alt="Thumbnail" />
                                </div>
                            </Tilty>
                        <div className="content">
                            <span className="subtitle">{data.subtitle}</span>
                            <h3 className="title">{data.title}</h3>
                            <p className='text-gray-300'>{data.paragraph}</p>
                        </div>
                    </div>
                    ))}
                </div>

            </div>
        </>
    )
}

export default FeaturesPossibility;