import React, { useState, useRef, useEffect } from 'react';
import Recruitment from '../../assets/images/blog/Topics/recruitment.png';
import SelfDevelopment from '../../assets/images/blog/Topics/selfdevelopment.png';
import Tips from '../../assets/images/blog/Topics/tips.png';
import Industry from '../../assets/images/blog/Topics/industry.png';
import Lifestyle from '../../assets/images/blog/Topics/lifestyle.png';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const BlogTopics = () => {

    

    return (
        <>    
            <section className="topics" id="topics" aria-labelledby="topic-label">
                <div className="container">
                    <div className="blog-card topic-card">
                        <div className="card-content">
                            <h2 className="headline headline-2 section-title card-title" id="topic-label">
                                Hot topics
                            </h2>
                            <p className="card-text">
                                Don't miss out on the latest news about recruitment, interview guides and employment regulations.
                            </p>

                        </div>

                        <div className="slider">
                            <ul className="slider-list">

                            <li class="slider-item">
                                <a href="#" class="slider-card">
                                <figure class="slider-banner img-holder" width= "507" height= "618">
                                    <img src={Recruitment} width="507" height="618" loading="lazy" alt="Recruitment"
                                    class="img-cover"></img>
                                </figure>
                                <div class="slider-content">
                                    <span class="slider-title">Recruitment</span>
                                    <p class="slider-subtitle">24 Articles</p>
                                </div>
                                </a>
                            </li>

                            <li class="slider-item">
                                <a href="#" class="slider-card">
                                <figure class="slider-banner img-holder" width= "507" height= "618">
                                    <img src={Tips} width="507" height="618" loading="lazy" alt="Tips and Tricks"
                                    class="img-cover"></img>
                                </figure>
                                <div class="slider-content">
                                    <span class="slider-title">Tips and Tricks</span>
                                    <p class="slider-subtitle">63 Articles</p>
                                </div>
                                </a>
                            </li>

                            <li class="slider-item">
                                <a href="#" class="slider-card">
                                <figure class="slider-banner img-holder" width= "507" height= "618">
                                    <img src={Industry} width="507" height="618" loading="lazy" alt="Industry News"
                                    class="img-cover"></img>
                                </figure>

                                <div class="slider-content">
                                    <span class="slider-title">Industry News</span>
                                    <p class="slider-subtitle">78 Articles</p>
                                </div>

                                </a>
                            </li>

                            <li class="slider-item">
                                <a href="#" class="slider-card">

                                <figure class="slider-banner img-holder" width= "507" height= "618">
                                    <img src={SelfDevelopment} width="507" height="618" loading="lazy" alt="Professional Development"
                                    class="img-cover"></img>
                                </figure>

                                <div class="slider-content">
                                    <span class="slider-title">Professional Development</span>
                                    <p class="slider-subtitle">125 Articles</p>
                                </div>

                                </a>
                            </li>

                            <li class="slider-item">
                                <a href="#" class="slider-card">
                                <figure class="slider-banner img-holder" width= "507" height= "618">
                                    <img src={Lifestyle} width="507" height="618" loading="lazy" alt="Lifestyle"
                                    class="img-cover"></img>
                                </figure>
                                <div class="slider-content">
                                    <span class="slider-title">Lifestyle</span>
                                    <p class="slider-subtitle">78 Articles</p>
                                </div>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>  
    </>
    )
}

export default BlogTopics;