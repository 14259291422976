import React from "react";
import { FeaturesHeader, FeaturesPossibility } from "../containers";
import PageLoader from "../containers/pageloader/Loader";
import { Helmet } from "react-helmet";


const Features = () => {

  return (
    <>
        <Helmet> 
          <title>Our Features</title>
          <meta
              name="description"
              content="Discover the powerful features that make Kwirl the ultimate destination for job seekers and employers alike. Explore our innovative tools and capabilities designed to streamline your recruitment process and elevate your career search to new heights."
          />
          <meta
              name="keywords"
              content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News"
          />
          </Helmet>
      <div className="main">
        <PageLoader/>
{/*         <FeaturesHeader /> */}
        <FeaturesPossibility />
      </div>
    </>
  );
}

export default Features;