import React from 'react';
import { useParams } from 'react-router-dom';
import BlogGridTwo from '../containers/blog/BlogGridTwo';
import BlogData from "../data/blog/BlogData.json";
import {slugify} from '../utils';
import { BreadCrumbCategory } from '../containers';


const allBlogData = BlogData;

const BlogCategory = () => {

    const params = useParams();
    const blogSlug = params.slug;

    const getBlogData = allBlogData.filter(blog => slugify(blog.category) === blogSlug);


    return (
        <>
            <main className="main-wrapper">
                <BreadCrumbCategory 
                title={blogSlug}
                page="Category"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">
                            <BlogGridTwo data={getBlogData} colSize="col-lg-4"/>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default BlogCategory;