import React from 'react';
import './bgimage.css';
import square from '../../assets/images/header/header_bg_image.png';

const Bgimage = () => {
    return (
      <div className='kwirl__bgimage'>
        <img src={square} alt='square'/>
      </div>
    )
  }
  
  export default Bgimage