import React from 'react';

import TermsConditionsHeader from '../containers/header/TermsConditionsHeader';

const TermsConditions = () => {

    return (
        <>
            <main className="">
{/*                 <TermsConditionsHeader/> */}
                <div className="p-24">
                    <div className="">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title mb-40">
                                    <h4 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">Product Terms & Conditions</h4>
                                </div>
                                <p className='text-gray-300'>The following Terms are applicable only where you have purchased an individual ad or a Monthly Subscription and are posting ads from one of those products: </p>
                                
                                <h4 className='mb-35'>Job Ads </h4>                      
                                
                                <p className='text-gray-300'>1. All job ads posted on the KWIRL platform remain posted for 30 days (unless removed earlier) and can be edited free of charge.</p>
                                <p className='mb-35 text-gray-300'>2. You are obliged to pay the full fee for each job ad, even if you request the withdrawal of your ad at any time (whether before or after it appears on the Site). Where you post a job ad to more than one location using the “Locations” function, each location will be posted as separate additional job ad and you are obliged to pay the full fee for each additional job ad in accordance with this clause.</p>
                                 

                                <h4 className='mb-35'>KWIRL Ad Types  </h4>  
                                <h4>Individual Ads  </h4>  
                                <p className="mb-20 text-gray-300">3. An individual ad is KWIRL’s basic job ad. An individual ad  entitles you to: </p>
                                
                                <p className='text-gray-300'>a. when an Individual is included within a candidates’ search results, the relevant role title for the ad, a brief description of the position you are advertising being displayed to candidates and your logo; and </p>
                                <p className='text-gray-300'>b. when selected by a candidate from KWIRL’s search results, the full contents of the job ad being displayed to the candidate. </p>                                    
                                
                                <p className='mb-20 text-gray-300'>4. From time to time KWIRL may repost your individual ad on the website/s of its related social platforms to increase the exposure of your ad. </p>

                                <h4>Standard Ads</h4> 
                                <p className="mb-20 text-gray-300" >5. A Standard ad listing entitles you to the features of an individual ad listing plus the following: </p> 
                                
                                  <p className='text-gray-300'>* Up to three active job ads per month;</p> 
                                  <p className='text-gray-300'>* One boosted job ad per month;</p> 
                                  <p className='text-gray-300'>* Access to all candidate profiles across the platform.</p>                                   
                                
                                <p className='mb-35 text-gray-300'>6. KWIRL reserves the right to withhold publication of any logo in the search results where KWIRL reasonably believes that the creative design is not a logo or is otherwise not appropriate for posting on the Site. </p> 

                                <h4>Medium Ads</h4> 
                                <p className="mb-20 text-gray-300" > 9. A medium subscription listing entitles you to the features of a Standard description plus the following: </p> 
                                
                                  <p className='text-gray-300'>An additional three active job ads per month;</p>  
                                  <p className='text-gray-300'>An additional two boosted jobs ad per month;</p>                                   
                                

                                <h4>Enterprise Ads</h4> 
                                <p className="mb-20 text-gray-300" >10. An Enterprise subscription listing entitles you to the features of a medium description plus the following: </p> 
                                
                                  <p className='text-gray-300'>An additional five active job ads per month;</p>  
                                  <p className='text-gray-300'>An additional two boosted jobs ad per month; </p>                                   
                                

                                <h4>Unused Boosted Ads</h4> 
                                <p className='mb-35 text-gray-300' >In the event registered users (Employer and Recruiters) have any remaining boosted ads unused from your monthly subscription, the remaining ad boost will tick over to the following month (inclusive to the new monthly entitlement)  </p> 

                                <h4>New Users (Employers & Recruiters)</h4> 
                                <p className='text-gray-300'> Newly first-time registered users (Employers & Recruiters) to the Kwirl platform are entitled to 3 months free on the platform prior to commencement of the subscription plan you have subscribed to.</p> 
                      
                                <h4>Monthly Subscriptions </h4> 
                                <p className='text-gray-300'>25. A Monthly (Job Ad) Subscription commits you to purchasing a minimum number of ads each month (“minimum monthly commitment”) over a 12 month period. For the avoidance of doubt, your specified limit per month refers to the total number of job ads that your Subscription allows you to post each month without having to pay additional fees and not the total number of concurrent job ads you may have listed at any one time. </p> 
                                <p className='text-gray-300'>26. You will be billed on the 15th of each month for your minimum monthly commitment. </p>
                                <p className='text-gray-300'>27. Should you post ads in addition to your minimum monthly commitment, you will be charged the individual job ad rate and will receive an additional invoice on the 1st business day of the following month for any such extra costs incurred. </p>

                                <h4>Candidate Search and KWIRL Profiles </h4> 
                                <p className="mb-20 text-gray-300" > The Candidate Search site allows users to search, refine and connect with candidates via a candidate’s KWIRL Profile in relation to advertisements placed on the Site. </p> 
                                
                                  <p className='text-gray-300' >29. A KWIRL Profile is created by a candidate and is a summary of information regarding their career and work history to which they may attach a portfolio of their work. </p>  
                                  <p className='text-gray-300'>30. KWIRL reserves the right to deny or remove your access to KWIRL Profiles for what it determines in its absolute discretion to be improper or incorrect use. </p>                                   
                                

                                <h4>Variation to Terms </h4> 
                                <p className='text-gray-300'> 31. We may vary these Terms at any time. We will provide notice by publishing the varied Terms on the Site and you accept that such publication will constitute sufficient notice.  </p>
                                <p className='text-gray-300'>32. You will be notified of any changes to the Terms which KWIRL reasonably believes will be materially detrimental to you (Detrimental Change) 30 days prior to them taking effect (Notice Period). </p>
                                <p className='mb-35 text-gray-300'>33. In the event of a proposed Detrimental Change, you may elect to terminate your agreement with us during the Notice Period by providing at least 5 days’ written notice. </p>
                                
                                <h4>Void or unenforceable terms </h4>
                                <p className='text-gray-300'>34 .Any clause within these Terms which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected. </p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default TermsConditions;