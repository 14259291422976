import React from "react";
import { FAQHeader } from "../containers";
import PageLoader from "../containers/pageloader/Loader";
import { Helmet } from "react-helmet";


const FAQ = () => {

  return (
    <div>
            <Helmet> 
                <title>FAQ</title>
                <meta
                    name="description"
                    content="Get answers to your burning questions quickly and easily with our FAQ page. Explore commonly asked queries about our services, policies, and more, streamlining your experience with Kwirl."
                />
                <meta
                    name="keywords"
                    content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News, Contact, Help, Assistance, FAQ"
                />
            </Helmet>
        <PageLoader/>
        <FAQHeader />
    </div>
  );
}

export default FAQ;