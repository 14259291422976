import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Footer } from './containers';
import ReactGA from 'react-ga';

// Home Page Import
import HomePage from './pages/HomePage';

// Pages Import
import Contact from './pages/Contact';
import Helphub from './pages/HelpHub';
import About from './pages/About';
import Features from './pages/Features';
import FAQ from './pages/FAQ';
import PricingPage from './pages/PricingPage';
import Recruiter from './pages/Recruiter';
import JobSeeker from './pages/JobSeeker';
import TermsConditions from './pages/TermsConditions'
import PrivacyPolicy from './pages/PrivacyPolicy';
import Resources from "./pages/Resoures";

// Blog Import
import BlogPage from './pages/BlogPage';
import BlogGridView from './pages/BlogPage';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';

/* import 'bootstrap/dist/css/bootstrap.min.css';  */

import WOW from "wow.js";

// CSS Import
import './assets/App.css'
/*import NavigationBar from "./components/navbar/NavigationBar"; */

import NavBar from "./components/navbar/Navbar";

ReactGA.initialize('G-N7S2L1016S'); // GOOGLE_MEASUREMENT_ID

export default function App() {

  return (
        <BrowserRouter>
          <NavBar />
          <Routes path="/" element={<HomePage />}>
            <Route index element={<HomePage />} />
              <Route path="/" element={<BlogPage />} />
              <Route path="contact-us" element={<Contact />} />
              <Route path="/" element={<Helphub />} />
              <Route path="pricing" element={<PricingPage />} />

              {/* Blogs */}
              <Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
              <Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
              <Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
              <Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/>

              <Route path="resources" element={<Resources />} />
              <Route path="about" element={<About />} />
              <Route path="features" element={<Features />} />
              <Route path="/" element={<FAQ />} />
              <Route path="recruiters" element={<Recruiter />} />
              <Route path="job-seekers" element={<JobSeeker />} />
              <Route path="terms-and-conditions" element={<TermsConditions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              {/* <Route path="advertisingtandc" element={<** />} /> */}
          </Routes>
          <Footer /> 
      </BrowserRouter>
  );
}


/* const App = () => {

  return (
    <>
      <Navbar />
      <Routes path="/" element={<Navbar />}>
        <Route index element={<HomePage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="contact" element={<Contact />} />
          <Route path="Helphub" element={<Helphub />} />
          <Route path="pricing" element={<PricingPage />} />

          
				  <Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
				  <Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				  <Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				  <Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/>

          <Route path="about" element={<About />} />
          <Route path="features" element={<Features />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="recruiter" element={<Recruiter />} />
          <Route path="jobseeker" element={<JobSeeker />} />
          <Route path="termsandconditions" element={<TermsConditions />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="advertisingtandc" element={<** />} /> 
      </Routes>
      <Footer /> 
    </>

  );
}

export default App; */
