import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';
import Circle2 from '../../assets/images/others/circle-2.png';
import Bubble2 from '../../assets/images/others/bubble-2.png';
import Bubble1 from '../../assets/images/others/bubble-1.png';
import Thumbnail from '../../assets/images/others/Recruiter-Thumb.jpg';

const RecruiterVideo = () => {
    const [toggler, setToggler] = useState(false);

    return (
        <>
            <div className="section section-padding-equal">
                <div className="container">
                    <div className="about-expert">
                        <div className="thumbnail">
                            <img src={Thumbnail} alt="Thumbnail" />
                            <div className="popup-video">
                                <button className="play-btn" onClick={() => setToggler(!toggler)}><FaPlay /></button>
                            </div>
                        </div>
                    </div>
                </div>
{/*                 <ul className="shape-group-16 img__list-unstyled">
                    <li className="shape shape-1"><img src={Circle2} alt="circle" /></li>
                    <li className="shape shape-2"><img src={Bubble2} alt="Line" /></li>
                    <li className="shape shape-3"><img src={Bubble1} alt="Line" /></li>
                </ul> */}
            </div>

            <FsLightbox toggler={ toggler } sources={ ['https://youtu.be/LspfprKPrrQ?feature=shared'] }/>
        </>
    );
}

export default RecruiterVideo;