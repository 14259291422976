import React from "react";
import img01 from "../../assets/images/others/Currenlty-Interviewing.png";

export default function WhoWeAre () {   
  return (
    <section className="pb-24">
    <div className="overflow-hidden ">
      <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-violet-700 dark:bg-gray-800 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-violet-300">Who we are</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">One app for all to recruit, collaborate and connect across the <span>globe.</span></p>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-violet-300" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                  <img
                    src={img01}
                    alt="Kwirl"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-100 dark:text-gray-500">
              We believe that finding the right job should be a fulfilling and exciting journey, not a daunting and tiresome process.
              <br/><br/>Say goodbye to endless hours spent sifting through job boards and crafting repetitive applications. With Kwirl, you'll unleash the power of advanced technology and cutting-edge features, putting you miles ahead of the competition.
              <br/><br/>Easily engage with the applicant, shortlist, interview and hire!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    
  );
};


