import React from "react";
import { HeaderTemplate, JobSeekerHeader, JobSeekerVideo, } from "../containers";
import PageLoader from "../containers/pageloader/Loader";
// import mainThumb from '../assets/images/header/Website Header Image.svg';

const JobSeeker = () => {

  return (

    <>
    <main className="">
        <PageLoader />
{/*         <JobSeekerHeader /> */}
        <JobSeekerVideo/>

    </main>
</>

  );
}

export default JobSeeker;