import React from 'react';
import blog01 from '../../assets/images/blog/blog01.png';
import blog02 from '../../assets/images/blog/blog02.png';
import blog03 from '../../assets/images/blog/blog03.png';
import moli from '../../assets/images/others/Moli.jpg';

const posts = [
  {
    title: 'Attracting And Selecting Top Talent',
    href: '#',
    category: { name: 'Article', href: '#' },
    description:
      'Finding and attracting top talent is essential to ensure the long-term success and growth of a company.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      blog01,
    readingTime: '5 min',
    author: {
      name: 'Moli Nauer',
      href: '#',
      imageUrl: moli,
    },
  },
  {
    title: 'Key Strategies For Personal Growth And Development',
    href: '#',
    category: { name: 'Article', href: '#' },
    description:
      'Success is a goal that many of us strive for in various aspects of our lives, whether it be personal or professional.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      blog02,
    readingTime: '4 min',
    author: {
      name: 'Moli Nauer',
      href: '#',
      imageUrl: moli,
    },
  },
  {
    title: 'Ace Your Next Job Interview',
    href: '#',
    category: { name: 'Article', href: '#' },
    description:
      'A job interview is a critical step in the hiring process that can determine whether you land your dream job or not.',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      blog03,
    readingTime: '4 min',
    author: {
      name: 'Moli Nauer',
      href: '#',
      imageUrl: moli,
    },
  },
]


export default function BlogHomeSection() {
  return (
<div className="relative  px-6 pb-20 pt-16 lg:px-8 lg:pb-28 lg:pt-24">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-violet-600 sm:text-5xl">From the blog</h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-700 sm:mt-4">
            Read the lastest news and articles!
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author.href} className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}










