/* import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline' */
import React from 'react';
import about from '../../assets/images/others/about-us.png'
import {
    AcademicCapIcon,
    CheckCircleIcon,
    HandRaisedIcon,
    RocketLaunchIcon,
    SparklesIcon,
    SunIcon,
    UserGroupIcon,
  } from '@heroicons/react/20/solid'
  
  const stats = [
    { label: 'Business was founded', value: '2022' },
    { label: 'People on the team', value: '4+' },
    { label: 'Users on the platform', value: '2k' },
    { label: 'Jobs Secured', value: '1k' },
  ]
  const values = [
    {
      name: 'Transparency',
      description: 'Transparency is something we all lack in one way shape or form. We are striving for a more open, honest and transparent future to give all humans the clarity they deserve.  ',
      icon: RocketLaunchIcon,
    },
    {
      name: 'Connection',
      description: 'When as a candidate or employer have you felt truly connected and enjoyed the hiring process? That’s why we are pushing boundaries to make hiring fun again for everyone.  ',
      icon: HandRaisedIcon,
    },
    {
      name: 'Opportunity',
      description: 'We truly believe that skills can be taught, character cannot. We want to create greater, equal opportunity by aligning employers and professionals to their core values.',
      icon: UserGroupIcon,
    },
    {
      name: 'Always learning.',
      description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
      icon: AcademicCapIcon,
    },
    {
      name: 'Share everything you know.',
      description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
      icon: SparklesIcon,
    },
    {
      name: 'Enjoy downtime.',
      description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
      icon: SunIcon,
    },
  ]
  const team = [
    {
      name: 'Leslie Alexander',
      role: 'Co-Founder / CEO',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
      location: 'Toronto, Canada',
    },
    // More people...
  ]
  const benefits = [
    'Competitive salaries',
    'Flexible work hours',
    '30 days of paid vacation',
    'Annual team retreats',
    'Benefits for you and your family',
    'A great work environment',
  ]
  
  export default function AboutSectionOne() {
  
  
    return (
      <div className="">
  
        <main className="relative isolate">
          {/* Background */}
          <div
            className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
            />
          </div>
  
          {/* Header section */}
          <div className="px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
              <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">So who's Kwirl?</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
              To help everyday professionals and employers collaborate and connect to create the most seamless and innovative hiring experience imaginable. Our core values define our approach to everything we do. 
              </p>
            </div>
          </div>
  
          {/* Content section */}
          <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className=" max-w-xl  gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
                <div className='text-gray-300'>                  
                    <p className='text-gray-300'>At Kwirl, we are not just a job board; we are architects of opportunity, curators of dreams, and champions of professional growth. Our mission is to revolutionize the job-seeking experience, connecting talented individuals with exciting career paths that align with their aspirations.</p>
                    <p className='text-gray-300'>Here at Kwirl, we believe that finding the perfect job should be an empowering journey, not a daunting task. With our user-friendly interface, cutting-edge features, and a diverse array of curated job listings, we strive to make job hunting a seamless and enjoyable process.</p>
                    <p className='text-gray-300'>Our commitment extends beyond mere placements; we are dedicated to fostering a community where talent meets opportunity.</p> 
                    <p className='text-gray-300'>Whether you're a seasoned professional or embarking on the first steps of your career journey, Kwirl is the catalyst that propels you toward success.</p>
                    <p className='text-gray-300'>Join Kwirl today, where your next career adventure awaits! 🌟</p>
                </div>
              </div>
              <dl className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
                {stats.map((stat, statIdx) => (
                  <div key={statIdx} className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                    <dt className="text-base leading-7 text-gray-300">{stat.label}</dt>
                    <dd className="text-3xl font-semibold tracking-tight text-white">{stat.value}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
  
          {/* Image section */}
          <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
            <img
              src= {about}
              alt=""
              className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
            />
          </div>
  
          {/* Values section */}
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our values</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
              Kwirl was founded set out to disrupt the way employers and professionals connect through the hiring process., but that’s not all.The name Kwirl and as brand is limitless and we are embarking on a journey towards putting our stamp on a better future, set out to enhance our key focus areas.
              </p>
            </div>
            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
              {values.map((value) => (
                <div key={value.name} className="relative pl-9">
                  <dt className="inline font-semibold text-white">
                    <value.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    {value.name}
                  </dt>{' '}
                  <dd className="inline">{value.description}</dd>
                </div>
              ))}
            </dl>
          </div>
  
          {/* Team section 
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our team</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Excepturi repudiandae alias ut. Totam aut facilis. Praesentium in neque vel omnis. Eos error odio. Qui
                fugit voluptatibus eum culpa.
              </p>
            </div>
            <ul
              role="list"
              className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
            >
              {team.map((person) => (
                <li key={person.name}>
                  <img className="aspect-[14/13] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
                  <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">{person.name}</h3>
                  <p className="text-base leading-7 text-gray-300">{person.role}</p>
                  <p className="text-sm leading-6 text-gray-500">{person.location}</p>
                </li>
              ))}
            </ul>
          </div> */}
  
          {/* CTA section */}
          <div className="relative isolate -z-10 mt-32 sm:mt-40">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                <img
                  className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                  src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                  alt=""
                />
                <div className="w-full flex-auto">
                  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Join our team</h2>
                  <p className="mt-6 text-lg leading-8 text-gray-300">
                    Looking to contribute to a company that has people at the forefront of everything they do? Join our team today and feel fulfilled with oppertunities, collaboration and make your voice heard!
                  </p>
                  <ul
                    role="list"
                    className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                  >
                    {benefits.map((benefit) => (
                      <li key={benefit} className="flex gap-x-3">
                        <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                  <div className="mt-10 flex">
                    <a href="https://app.kwirl.com.au" className="text-sm font-semibold leading-6 text-indigo-400">
                      See our job postings <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                style={{
                  clipPath:
                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                }}
              />
            </div>
          </div>
        </main>
              
        
      </div>
    )
  }
  


/* 
const AboutSectionOne = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={about} alt="Kwirl" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className=" heading-left">
                            <span className="subtitle">Who we are</span>
                            <h2 className="title">We are Kwirl</h2>
                            <div className='kwirl__about-content'>
                                <p>At Kwirl, we are not just a job board; we are architects of opportunity, curators of dreams, and champions of professional growth. Our mission is to revolutionize the job-seeking experience, connecting talented individuals with exciting career paths that align with their aspirations.</p>
                                <p>Here at Kwirl, we believe that finding the perfect job should be an empowering journey, not a daunting task. With our user-friendly interface, cutting-edge features, and a diverse array of curated job listings, we strive to make job hunting a seamless and enjoyable process.</p>
                                <p>Our commitment extends beyond mere placements; we are dedicated to fostering a community where talent meets opportunity.</p> 
                                <p>Whether you're a seasoned professional or embarking on the first steps of your career journey, Kwirl is the catalyst that propels you toward success.</p>
                                <p>Join Kwirl today, where your next career adventure awaits! 🌟</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutSectionOne; */