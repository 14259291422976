import React from 'react';
import ResourcesPage from '../containers/Resources';
import HeroResources from '../containers/header/ResourcesHero';

export default function Resources() {
  return (
    <div>
      <HeroResources />
      <ResourcesPage/>
    </div>
  )
}