import React from 'react';
import { AboutHeader, AboutSectionOne } from '../containers';
import PageLoader from '../containers/pageloader/Loader';
import { Helmet } from "react-helmet";

const About = () => {

    return (
        <>
             <main >
             <Helmet> 
                <title>About Kwirl</title>
                <meta
                    name="description"
                    content="Whether you're looking to take the next step in your career or find the perfect candidate to join your team, Kwirl Job Board is here to help you make the right connections. Join Kwirl today and unlock a world of opportunities."
                />
                <meta
                    name="keywords"
                    content="Recruitment, Jobs, Career, Employment, Resume, Job Search"
                />
                </Helmet>

                <PageLoader/>
{/*                 <AboutHeader /> */}
                <AboutSectionOne />
            </main>
        </>
    )
}

export default About;