import React from 'react';
import Slider from "react-slick";
import SplashData from '../data/splash/SplashData.json';

const DemoData = SplashData[0];

var slideSettings = {
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            variableWidth: false
          }
        }
    ]
}

const ImgSlider = () => {
    return (
        <div className="section splash-main-banner pb-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className='mx-auto max-w-4xl text-center'>
                <span className="">Friendly UI</span>
                <h2 className='mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl'>Our Application</h2>                
            </div>
            </div>
            
            <div className="demo-slider">
                <Slider {...slideSettings} className="slick-dot-nav">
                    {DemoData.slice(1,8).map((data) => (
                        <div className="single-slide" key={data.id}>                            
                            <img src={`${process.env.PUBLIC_URL}${data.width_img}`} alt="Demo" />                            
                        </div>
                    ))}
                </Slider> 
            </div>
        </div>
    )
}

export default ImgSlider;