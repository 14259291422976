import React, { useEffect, useState } from "react";
import SlickSlider from "./SlickSlider";
import WhyChooseUsItem from "./WhyChooseUsItem";
import calendly from '../../assets/icons/icon-43.png';
import applicant from '../../assets/icons/icon-34.png';
import fast from '../../assets/icons/icon-28.png';
import easy from '../../assets/icons/icon-32.png';
import collaborate from '../../assets/icons/icon-38.png';
import smart from '../../assets/icons/icon-31.png';
import teamwork from '../../assets/icons/icon-41.png';
import post from '../../assets/icons/icon-42.png';

const WhyChooseKwirl = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: fast,
      alt: "Hired Fast",
      link: "/",
      title: "Hired Fast",
      description: "Efficiently review application ensuring you focus on the most qualified candidates",
    },
    {
      src: easy,
      alt: "Hired Easy",
      link: "/",
      title: "Hired Easy",
      description: "A streamlined solution that simplifies the hiring process and helps employers find the perfect candidates efficiently",
    },
    {
      src: post,
      alt: "Job Posting",
      link: "/",
      title: "Job Posting",
      description: "Easily create and manage job postings, specifying requirements and preferences",
    },
    {
      src: collaborate,
      alt: "Collaborate",
      link: "/",
      title: "Collaborate",
      description: "Collaborate with your team throughout the process, enhancing communication and decision-making",
    },
    {
      src: applicant,
      alt: "",
      link: "/",
      title: "Screening",
      description: "A streamlined solution simplifying the hiring process, find the perfect candidates efficiently",
    },
    {
      src: teamwork,
      alt: "",
      link: "/",
      title: "Teamwork",
      description: "Add your team to the hiring process, with account privliges and the ability to add them to sepecific jobs",
    },
    {
      src: smart,
      alt: "",
      link: "/",
      title: "Smart Contracts",
      description: "Easily submit your letters of offer through the application directly to the candidate",
    },
    {
      src: calendly,
      alt: "",
      link: "/",
      title: "Interview Scheduling",
      description: "Interview scheduling within the app with our Calendly integration",
    },
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section className="choose-area pb-50">
      <div className="">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="">Why Choose Kwirl</span>
              <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                What sets us <span>apart</span>
              </h2>
            </div>
          </div>
        </div>

         <div className="row choose-active">
          <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div> 
      </div>
    </section>
  );
};

export default WhyChooseKwirl;
