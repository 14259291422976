import React from "react";
import { Link } from "react-router-dom";

const WhyChooseUsItem = (props) => {
  return (
    <div className="min-h-[382px] border border-violet-300 rounded-[30px] p-[60px_40px] transition duration-300 ease-in-out">
      <div className="w-20 h-20 flex items-center justify-center bg-violet-500 bg-opacity-7 border-4 border-violet-300 border-opacity-6 rounded-full mb-7 transition duration-300 ease-in-out">
        <img src={props.item.src} alt={props.item.alt} />
      </div>
      <div className="text-lg mb-6 font-medium normal-case leading-[1.27]">
        <h2 className="">
          <Link to={props.item.link}>{props.item.title}</Link>
        </h2>
        <p className="text-gray-300">{props.item.description}</p>
      </div>
    </div>
  );
};

export default WhyChooseUsItem;
