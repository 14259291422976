import React from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterest, FaYoutube} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import WidgetCategory from './widget/WidgetCategory';
import WidgetPost from './widget/WidgetPost';
import logo from '../../assets/images/logo/Kwirl_White_Logo.png';
import P1 from '../../assets/images/blog/Instagram/1.svg';
import P2 from '../../assets/images/blog/Instagram/2.svg';
import P3 from '../../assets/images/blog/Instagram/3.svg';
import P4 from '../../assets/images/blog/Instagram/4.svg';
import P5 from '../../assets/images/blog/Instagram/5.svg';
import P6 from '../../assets/images/blog/Instagram/6.svg';
import P7 from '../../assets/images/blog/Instagram/Hot Pink Neon Retro TV Glitch Quote Animated Social Media  (1).svg';
import P8 from '../../assets/images/blog/Instagram/Modern Startup Hiring Vacancy LinkedIn Post.svg';
import P9 from '../../assets/images/blog/Instagram/Sign up today.svg';

const BlogSidebar = () => {
    return (
        <div className="axil-sidebar">
{/*             <div className="widget widget-search">
                <h4 className="widget-title">Search</h4>
                <form action="#" className="blog-search">
                    <input type="text" placeholder="Search…" />
                    <button className="search-button"><FaSearch /></button>
                </form>
            </div> */}
            <div className="widget widget-categories aside-card blog-card">
                <h4 className="widget-title">Categories</h4>
                <WidgetCategory />
            </div>
            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">Follow:</h5>
                    <ul className="social-list list-unstyled">
                        <li><a href="https://facebook.com/"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/"><FaXTwitter /></a></li>
                        <li><a href="https://www.instagram.com/"><FaInstagram /></a></li>
                        <li><a href="https://www.linkedin.com/"><FaLinkedinIn /></a></li>
                        <li><a href="https://www.pinterest.com/"><FaPinterest /></a></li>
                        <li><a href="https://www.youtube.com/"><FaYoutube /></a></li>
                    </ul>
                </div>
            </div>
            <div className="widget widget-recent-post aside-card blog-card">
                <h4 className="widget-title">Recent post</h4>
                <WidgetPost />
            </div>
            <div class="blog-card aside-card insta-card">
              <a href="#" class="logo">
                <img src={logo}  alt="Kwirl logo"/></a>

              <p class="card-text">Follow us on instagram @kwirl_ </p>

              <ul class="insta-list">
                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P1} alt="insta post" width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P2} alt="insta post" width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P3} alt="insta post" width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P4}  alt="insta post" width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P5} width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P6} alt="insta post" width="276" height="277" className="img-cover"/></a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P7} alt="insta post" width="276" height="277" className="img-cover"/>
                  </a>
                </li>

                <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P8}  alt="insta post" width="276" height="277" className="img-cover"/>
                  </a>
                </li>

{/*                 <li>
                  <a href="www.instagram.com/kwirl_" class="insta-post img-holder" width="276" height="277">
                    <img src={P9}  alt="insta post" width="276" height="277" className="img-cover"/>
                  </a>
                </li> */}

              </ul>

            
            </div>
        </div>
    )
}

export default BlogSidebar;