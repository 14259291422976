import React from 'react';
import { Link } from 'react-router-dom';
/* import bubble9 from "../../assets/images/others/bubble-.png";
import bubble11 from "../../assets/images/others/bubble-1.png"; */



const BreadCrumbAuthor = ({title, page}) => {
    return (
        <div className="breadcrum-area">
            <div className="container">
                <div className="breadcrumb">
                    <ul className="list-unstyled">
                        <li>
                            <Link to={process.env.PUBLIC_URL + "/blog"}>Blogs</Link>
                        </li>
                        <li className="active" dangerouslySetInnerHTML={{__html: page}}></li>
                    </ul>
                    <h1 className="title h2" dangerouslySetInnerHTML={{__html: title}}></h1>
                </div>
            </div>
{/*             <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1"><img src={bubble9} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={bubble11} alt="Bubble" /></li>                
            </ul> */}
        </div>
    )
}

export default BreadCrumbAuthor;