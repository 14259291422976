import React from 'react';
import { useParams } from 'react-router-dom';
import BlogGridTwo from '../containers/blog/BlogGridTwo';
import BlogData from "../data/blog/BlogData.json";
import {slugify} from '../utils';
import BreadCrumbAuthor from '../containers/headerpagetemplate/BreadCrumbAuthor';
import { Helmet } from "react-helmet";

const allBlogData = BlogData;



const BlogArchive = () => {

    const params = useParams();
    const blogSlug = params.slug;

    const getBlogData = allBlogData.filter(blog => slugify(blog.author_name) === blogSlug);
    

    return (
        <>
            <Helmet> 
                <title>Blog Author</title>
                <meta
                    name="description"
                    content="Explore Kwirl's author-specific blogs, packed with insights and resources tailored to empower."
                />
                <meta
                    name="keywords"
                    content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News"
                />
            </Helmet>
            <main className="main-wrapper">
                <BreadCrumbAuthor 
                title={getBlogData[0].author_name}
                page="Blog Author"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">
                            <BlogGridTwo data={getBlogData} colSize="col-lg-4"/>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default BlogArchive;