import React from "react";
import { RecruiterHeader, RecruiterVideo, RecruitersSection } from "../containers";
import PageLoader from "../containers/pageloader/Loader";


const Recruiter = () => {

  return (
    <div>
        <PageLoader/>
{/*         <RecruiterHeader /> */}
        <RecruiterVideo />
        
    </div>
  );
}

export default Recruiter;