/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useState } from 'react';
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Helmet } from "react-helmet";
import useAnalyticsEventTracker from "../utils/useAnalyticsEventTracker";


export default function ContactUs() {
  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const [resultText, setResultText] = useState('');

  const handleSubmit = (event) => {
      event.preventDefault();

      const { name, email, message } = event.target;

      const endpoint = "https://0b76e0uoql.execute-api.us-east-1.amazonaws.com/default/SendContactEmail";
      const body = JSON.stringify({
          senderName: name.value,
          senderEmail: email.value,
          message: message.value
      });

      const requestOptions = {
          method: "POST",
          body
      };

      fetch(endpoint, requestOptions)
          .then((response) => {
              if (!response.ok) throw new Error("Error in fetch");
              return response.json();
          })
          .then(() => {
              setResultText("Email sent successfully!");
          })
          .catch(() => {
              setResultText("An unknown error occurred.");
          });
  };
  return (
    <>
      <Helmet> 
      <title>Contact Us at Kwirl</title>
      <meta
          name="description"
          content="Need help, assistance or have a suggestion? Simply contact us at Kwirl."
      />
      <meta
          name="keywords"
          content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News, Contact, Help, Assistance"
      />
      </Helmet>    
      <div className="relative isolate ">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg x="100%" y={-1} className="overflow-visible fill-violet-500/20 dark:fill-gray-800/20">
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect width="100%" height="100%" strokeWidth={0} fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
                </svg>
                <div
                  className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                  aria-hidden="true"
                >
                  <div
                    className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                    style={{
                      clipPath:
                        'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                    }}
                  />
                </div>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-white">Get in touch</h2>
              <p className="mt-6 text-lg leading-8 text-gray-200">
              Have questions, suggestions, or just want to say hello? We'd love to hear from you! Contact the Kwirl team for personalized assistance, support, or partnership inquiries. Your feedback fuels our commitment to excellence. Reach out and let's connect – because at Kwirl, your journey matters.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    Queensland, Australia
                    <br />
                    Gold Coast
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-white" href="tel:+1 (555) 234-5678">
                      +61 (457) 226 941
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-white" href="mailto:hello@example.com">
                      contact@kwirl.com.au
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <form method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48 " onSubmit={handleSubmit}>
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg" >
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                    First name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                    Last name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-white">
                    Phone number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel"
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      defaultValue={''}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-400"
                  onClick={()=>gaEventTracker('email')}
                  >
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

/* 
import { ContactForm  } from "../containers";
import bubble1 from '../assets/images/others/bubble-1.png';
import bubble2 from '../assets/images/others/bubble-2.png';
import circle3 from '../assets/images/others/circle-3.png'; 
import ContactHeader from "../containers/header/ContactHeader";
import PageLoader from "../containers/pageloader/Loader";


const Contact = () => {

  return (
    <>

        <PageLoader/>
        <ContactHeader />
        <div className="section section-padding">
          <div className="container">
              <div className="row">
                  <div className="col-xl-5 col-lg-6">
                      <div className="contact-form-box shadow-box mb--30">
                          <h3 className="title">Reach Out</h3>
                          <ContactForm />
                      </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 offset-xl-1">
                      <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                          <h4 className="title">Contact Us</h4>
                          <p>Our customer care is open from Mon-Fri, 09:00am to 5:00pm</p>                          
                      </div>
                      <div className="contact-info mb--30">
                          <h4 className="title">Email</h4>
                          <p>Our support team will get back to you within 24hours during standard business hours.</p>
                          <a href="mailto:contact@kwirl.com.au" >contact@kwirl.com.au</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </>
  );
}

export default Contact; */