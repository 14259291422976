import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {  
  FingerPrintIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, EnvelopeOpenIcon, PlayCircleIcon, Bars3Icon } from '@heroicons/react/20/solid'
import logo from '../../assets/images/logo/Kwirl_White_Logo.png';
import logoPurple from '../../assets/images/logo/Kwirl_Purple_Logo.png';
import favicon from '../../assets/images/logo/Circle_Purple_White_Logo.png';
import { Button, Dropdown, Avatar } from 'flowbite-react';
import { toggleTheme } from '../../redux/theme/themeSlice';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux'; 
import { Link, useLocation } from 'react-router-dom';
// import { signoutSuccess } from '../redux/user/userSlice';

const dropdown = [
  {
    name: 'Recruiters',
    description: 'Revolutionize your talent acquisition game with unparalleled features',
    href: '/recruiters',
    icon: UserGroupIcon,
  },
  {
    name: 'Job Seekers',
    description: 'Unlock the door to endless opportunities and seamless job hunting like never before',
    href: '/job-seekers',
    icon: UserCircleIcon,
  },
  {
    name: 'All Features',
    description: 'From intuitive document uploads and seamless scheduling with Calendly',
    href: '/features',
    icon: SquaresPlusIcon,
  },
  { 
    name: 'Security', 
    description: 'Keeping your data safe and secure', 
    href: '#', 
    icon: FingerPrintIcon },
]
const callsToAction = [
  { name: 'Recruiter demo', href: '#', icon: PlayCircleIcon },
  { name: 'Job seeker demo', href: '#', icon: PlayCircleIcon },
  { name: 'Get in touch', href: '#', icon: EnvelopeOpenIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  const path = useLocation().pathname;
   const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
/*   const { currentUser } = useSelector(state => state.user); */
  const { theme } = useSelector(state => state.theme); 

  /* Signout function 
  const handleSignout = async () => {
    try {
      const res = await fetch('/api/user/signout', {
        method: 'POST',
      });
      const data = await res.json();
      if (!res.ok) {
        console.log(data.message);
      }
      else {
        dispatch(signoutSuccess());
      }
    } catch (error) {
      console.log(error.message);
    }
  };*/

  return (
    <header className="sticky top-0 isolate z-10 bg-white dark:bg-gray-900 dark:border-b border-white/10">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Company Logo</span>
            <img className="h-8 w-auto" src={theme === 'light' ? logoPurple : logo } alt="" />
          </a>
        </div>

         <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 dark:fill-violet-300" aria-hidden="true" />
          </button>
        </div> 
        <Popover.Group className="hidden lg:flex lg:gap-x-12 flex-grow items-center justify-center">
          <Popover>
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900  dark:text-white">
              Features
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >

              {/* ==== Dropdown menu Items ==== */}
              <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white dark:bg-violet-600 pt-14 shadow-lg ring-1 ring-gray-900/5 ">
                <div className="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  {dropdown.map((item) => (
                    <div key={item.name} className="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-violet-500 ">
                      <div className="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-violet-100">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <a href={item.href} className="mt-6 block font-semibold text-gray-900 dark:text-gray-200">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600 dark:text-gray-100">{item.description}</p>
                    </div>
                  ))}
                </div>
                <div className="bg-gray-50">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5">
                      {callsToAction.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                        >
                          <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          {/* Header Navigation */}
          <a href="/about" className="text-sm font-semibold leading-6 text-gray-900  dark:text-white">About</a>
          <a href="/blog" className="text-sm font-semibold leading-6 text-gray-900  dark:text-white">Blog</a>
          <a href="/contact-us" className="text-sm font-semibold leading-6 text-gray-900  dark:text-white">Contact Us</a>
          
          {/* Dark or Light Theme Button and sign in container */}

          {/* Dark or Light Theme Button */}
          <div className='flex gap-2 md:order-2'>
            <Button className='w-12 h-10 hidden sm:inline' color='gray' pill onClick={() => dispatch(toggleTheme())}>
              {theme === 'light' ? <FaSun/> : <FaMoon/>}
            </Button>

          {/* Sign in button to show only if current user doesnt exist, if user exists show profile pic and dropdown*/}
{/*           {currentUser ? (
            <Dropdown arrowIcon={false} inline label={ <Avatar alt='user' img={currentUser.profilePicture} rounded />}>
              <Dropdown.Header>
                <span className='block text-sm'>@{currentUser.username}</span>
                <span className='block text-sm font-medium truncate'>{currentUser.email}</span>
              </Dropdown.Header>

               links to profile inside dashabord, for admin other options COMMENT LINE
              <Link to={'/dashboard?tab=profile'}>
                <Dropdown.Item>Profile</Dropdown.Item>
              </Link>
              <Dropdown.Divider />  Line break COMMENT LINE
              <Dropdown.Item onClick={handleSignout}>Sign Out</Dropdown.Item>
            </Dropdown>
          ):
          (
             Sign in button if user doesnt exist COMMENT LINE Delete button sign in once lambda configured
            <Link to='/sign-in'>
              <Button gradientDuoTone='purpleToBlue' outline pill>Log in <span aria-hidden="true">&rarr;</span></Button>
            </Link>
          )} */}

            <Link to='https://app.kwirl.com.au'>
              <Button gradientDuoTone='purpleToBlue' outline pill>Log in <span aria-hidden="true">&rarr;</span></Button>
            </Link>
          </div>
        </Popover.Group>
      </nav>

      {/* ===== Mobile Menu ==== */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Company</span>
              <img
                className="h-8 w-auto"
                src={favicon}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Product
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...dropdown, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* Mobile Navigation */}
                <a
                  href="/features"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Features
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Marketplace
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Company
                </a>
              </div>
              <div className="py-6">
                <a
                  href='https://app.kwirl.com.au'
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}