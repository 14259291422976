import React from 'react';
import BlogSidebar from '../containers/blog/BlogSidebar';
import BlogGridOne from '../containers/blog/BlogGridOne';
import BlogHeader from '../containers/header/BlogHeader';
import PageLoader from '../containers/pageloader/Loader';
import BlogTopics from '../containers/blog/BlogTopics';
import { Helmet } from "react-helmet";

const BlogGridView = () => {

    return (
        <>
            <Helmet> 
                <title>Kwirl Blogs</title>
                <meta
                    name="description"
                    content="Dive into expert tips, insights, and resources tailored for landing your next career move or hiring your next candidate."
                />
                <meta
                    name="keywords"
                    content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News"
                />
            </Helmet>
            <PageLoader/>
            <BlogHeader/>
            <BlogTopics />
            <main className="main-wrapper">
                <div className="section-padding-equal">
                    <div className="container">
                       <div className="row row-40">
                            <div className="col-lg-8">
                                <BlogGridOne />
                            </div>
                            <div className="col-lg-4">
                                <BlogSidebar />
                            </div>
                       </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default BlogGridView;