import React from "react";
import { Blog, Possibility, Pricing, Whatkwirl, HeaderHomePage, Ghost, Resume, ImgSlider, SectionTitle, BlogHomeSection, WhoWeAre, WhyChooseKwirl } from '../containers';
import { BgImage, Brand, CTA } from '../components'; 
import PageLoader from "../containers/pageloader/Loader";
import { PrimaryFeatures } from "../containers/PrimaryFeatures";
import Subscribe from "../containers/Subscribe";
import ConnectAcrossGlobe from "../containers/ConnectAcrossGlobe";
import FAQ from "../containers/FAQAccordion";
/* import WhyChooseKwirl from "../containers/whychoosekwirl/WhyChooseKwirl"; */

const HomePage = () => {

  return (
    <>
      <main className="main-wrapper">
        <PageLoader />
        <BgImage />
        <HeaderHomePage />
        <ConnectAcrossGlobe />
        <WhoWeAre />
        <Pricing />
        <PrimaryFeatures />
        <WhyChooseKwirl />   
        <ImgSlider />
        <BlogHomeSection />
        <FAQ />
        <Subscribe />
      </main>
    </>
  );
}

export default HomePage;