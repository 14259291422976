import React from 'react';
import BackGroundShadows from '../containers/header/BackGroundShadows';
import PrivacyAccordion from '../containers/privacypolicy/PrivacyAccordion';

const PrivacyPolicy = () => {

    return (
        <>
            <main className="">                
{/*                 <BackGroundShadows /> */}
                    <div className="row justify-content-center">                              
                    <PrivacyAccordion/>
                </div>
            </main>
        </>
    )
}

export default PrivacyPolicy;