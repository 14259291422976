import React from 'react';
// import people from '../../assets/images/header/people.png';
// import app, { ReactComponent as App } from '../../assets/images/header/Website Header Image.svg';
import app, { ReactComponent as App } from '../../assets/images/header/App.png';
// import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import logo from '../../assets/images/logo/Circle_Purple_White_Logo.png';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export default function Header () {
  return (
    <section className='relative isolate overflow-hidden pb-24'>
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
      <defs>
        <pattern
          id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
          width={200}
          height={200}
          x="50%"
          y={-1}
          patternUnits="userSpaceOnUse"
        >
          <path d="M.5 200V.5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y={-1} className="overflow-visible fill-violet-500/20 dark:fill-gray-600/20">
        <path
          d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
          strokeWidth={0}
        />
      </svg>
      <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
    </svg>
    <div
      className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      aria-hidden="true"
    >
      <div
        className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#ddd6fe] to-[#8b5cf6] opacity-20"
        style={{
          clipPath:
            'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
        }}
      />
  </div>
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-20 gap-28">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
        <img
            className="h-11"
            src={logo}
            alt="Small Kwirl Logo"
          />
          <div className="mt-20 sm:mt-32 lg:mt-16">
            <a href="#" className="inline-flex space-x-6">
              <span className="rounded-full bg-violet-700 px-3 py-1 text-sm font-semibold leading-6 text-violet-50 ring-1 ring-inset ring-violet-500 dark:bg-gray-700">
                BETA
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                <span>Just shipped v1.0</span>
                <ChevronRightIcon className="h-5 w-5 text-violet-500" aria-hidden="true" />
              </span>
            </a>
          </div>
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Collab Together<span style={{ color: '#f102fd' }}>.</span> 
          <br/>Hire Better<span style={{ color: '#149bf5' }}>.</span></h1>
          <p className="mt-6 text-lg leading-8 text-gray-200">
            Helping jobseekers, employers and recruiters collaborate and connect to create the most seamless recruiting experience possible.</p>

            {/*  ==== Email Find Out More ==== */}
            <form 
            className="w-full max-w-md mt-8"
            action="https://kwirl.us21.list-manage.com/subscribe/post?u=552360dc0fa085ae899bbd2f4&amp;id=168fc743b3&amp;f_id=00cd83e6f0" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            class="validate" 
            target="_blank">
            <div className="flex gap-x-4">

              <input 
              type="email" 
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              name="EMAIL" 
              id="mce-EMAIL" 
              placeholder="Your Email Address" />

              <button 
              type="submit" 
              name="subscribe" 
              id="mc-embedded-subscribe" 
              className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              value="Subscribe">Find Out More</button>
          </div>
            </form>

          <div className="mt-8 flex items-center gap-x-6">
            <a
              href="#"
              className="rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-400"
            >
              Get started
            </a>
            <a href="/features" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
          
{/*           <div className="mt-8 ">
            <p className='inline-flex gap-6 text-gray-100'><IoIosCheckmarkCircleOutline className='react-icons' color='green' size={20} /><strong className='text-white'>First three months free</strong>for Employers & Recruiters</p>
            <p className='inline-flex gap-6 text-gray-100'><IoIosCheckmarkCircleOutline className='react-icons' color='green' size={20} /><strong className='text-white'>Free forever</strong> for Job seekers</p>
          </div> */}

        </div>

       <div className="mt-16 sm:mt-24 sm:align-right lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
          <img src={app} 
          alt='app'
          width={600}
          height={600}/>
        </div> 
      </div>

      {/*  ==== Small info section ==== */}
      <div className="flex justify-center ">
           <p className="relative rounded-full px-4 text-xs leading-6 ring-1 ring-inset ring-violet-500 bg-violet-700 hover:bg-violet-600 dark:bg-gray-700">
             <span className="hidden md:inline text-gray-100">
               Kwirl saves 50% on time to fullfill roles, saving recruiters and job seekers time and effort...    
             </span>
             <a href="/features" className="font-semibold text-violet-50">
               <span className="absolute inset-0" aria-hidden="true" /> See How{''}
               <span aria-hidden="true">&rarr;</span>
             </a>
           </p>
         </div>
      {/*  ==== End small info section ==== */}

    </section>
  )
}

