import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import reportWebVitals from './reportWebVitals.js';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from './theme/ThemeProvider.jsx';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store.js';
import ReactGA from 'react-ga';
ReactGA.initialize('G-N7S2L1016S'); // GOOGLE_MEASUREMENT_ID


ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
  </PersistGate>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
