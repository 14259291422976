import React from "react";
import { Pricing, PricingFaq, PricingHeader } from "../containers";
import PageLoader from "../containers/pageloader/Loader";
import { Helmet } from "react-helmet";
import FAQAccordion from '../containers/FAQAccordion';

const PricingPage = () => {

  return (
    <>
        <Helmet> 
          <title>Our Features</title>
          <meta
              name="description"
              content="Explore our transparent and flexible pricing options tailored to meet your needs. Discover the perfect plan to unlock all the benefits of Kwirl's premium features and take your recruitment efforts or job search to the next level."
          />
          <meta
              name="keywords"
              content="Recruitment, Jobs, Career, Employment, Resume, Job Search, Blogs, News, Industry News, Pricing"
          />
        </Helmet>
      <main className="main-wrapper">
        <PageLoader />
{/*         <PricingHeader /> */}
              <Pricing />
{/*         <PricingFaq /> */}
        <FAQAccordion />
      </main>
    </>
  );
}

export default PricingPage;