import React from "react";
import { HelpHubHeader, HelpHubNav } from "../containers";
import PageLoader from "../containers/pageloader/Loader";



const HelpHub = () => {

  return (
    <div>
        < PageLoader />
        < HelpHubHeader />
        < HelpHubNav />
    </div>
  );
}

export default HelpHub;